import React, { useMemo } from "react"
import { Box, HStack, Image, Link } from "@chakra-ui/react"
import { hasFlag } from "country-flag-icons"
import { getSlug } from "utils/helpers"
import { Link as GatsbyLink } from "gatsby"

export const UpdateCountry = ({ country }) => {
  const flagSupported = useMemo(() => {
    return hasFlag(country.countryCode)
  }, [country])

  return (
    <HStack spacing="4">
      {flagSupported && (
        <Box padding="0px 0">
          <Link as={GatsbyLink} to={`/country/${getSlug(country.name)}`} >
            <Image
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.countryCode}.svg`} 
              w="30px"
              h="30px"
              objectFit="cover"
              borderRadius="100%"
              maxW="30px"
              m="5px 15px 0 0px"
              border="solid 0px lightgray"
            />
          </Link>
        </Box>
      )}
    </HStack>
  )

}
