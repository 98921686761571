import React from 'react'
import {
  Box,
  Flex,
  Button,
  Divider,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react'
import { Row } from './Row'
import { MarkdownText } from 'components/common/Text'
import { ZoomAlert } from 'components/ZoomAlert'

export const TrackerTable = ({ data, changeTab, getTabId }) => {
  // // In-memory Search box: (uses unnested data.allCountries, rather than data.countries, which has EU countries nested under EU)
  // const [countries, setCountries] = useState(data.countries)
  // const [searchValue, setSearchValue] = useState('')
  // const filterCountries = useCallback(
  //   (e) => {
  //     // add eucountries
  //     let val = e.target.value
  //     setSearchValue(val)
  //     if (val) {
  //       setCountries(
  //         data.allCountries.filter(
  //           (country) =>
  //             country.name.toLowerCase().indexOf(val.toLowerCase()) > -1
  //         )
  //       )
  //     } else {
  //       setCountries(data.countries)
  //     }
  //   },
  //   [setCountries, data]
  // )

  return (
    <Stack spacing="5">
      {/* <Stack
        direction={{
          base: 'column',
          md: 'row',
        }}
        justify="space-between"
        px={{
          base: '4',
          md: '6',
        }}
        py="5"
        spacing={5}
      >
        <Box>
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Icon as={FiSearch} color="muted" boxSize="5" />
            </InputLeftElement>
            <Input
              placeholder="Search for a country..."
              value={searchValue}
              onChange={filterCountries}
            />
          </InputGroup>
        </Box>
        <Legend />
      </Stack> */}
      <Box my="2" pt="20px">
        <ZoomAlert />
      </Box>
      <Flex justifyContent="space-between" p="0 0 5px">
        <Box></Box>
        <Box>
          <Text
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="1px"
            color="#373468"
            fontSize="sm"
            display={{ base: 'none', md: 'flex' }}
          >
            Hover over each cell to view more information
          </Text>

          <Text
            fontWeight="500"
            textTransform="uppercase"
            letterSpacing="1px"
            color="#373468"
            fontSize="sm"
            display={{ base: 'flex', md: 'none' }}
          >
            Click on a country to view more information on the sanctions they’ve
            implemented.
          </Text>
        </Box>
      </Flex>
      <Box>
        <Table id="country__table" position="relative">
          <Thead
            id="table__head"
            bg="#282828"
            borderRadius="0px"
            top="0"
            position="sticky"
          >
            <Tr id="table__row" mb="25px">
              <Th
                h="60px"
                cursor="default"
                fontWeight="600"
                border="none"
                verticalAlign="middle"
                color="white"
                w={{ base: '60%', md: 'auto', lg: 'auto', xl: 'auto' }}
                _hover={{ background: '#0056bd' }}
              >
                <Text
                  fontSize={{ base: 'xs', md: '11px', xl: 'xs' }}
                  lineHeight="11px"
                  width="100%"
                >
                  Countries
                </Text>
              </Th>
              <Th
                h="60px"
                cursor="default"
                fontWeight="600"
                border="none"
                verticalAlign="middle"
                color="white"
                textAlign="center"
                w={{ base: '40%', md: 'auto' }}
                _hover={{ background: '#0056bd' }}
                bg={{ base: 'inherit', md: 'inherit' }}
              >
                <Text
                  fontSize={{ base: 'xs', md: '11px', xl: 'xs' }}
                  lineHeight="11px"
                  px="1"
                  pt="0"
                  pb="1"
                  width="100%"
                  textAlign="center"
                >
                  Grade
                </Text>
              </Th>
              {data.proposals.map(proposal => (
                <Th
                  key={proposal.id}
                  h="60px"
                  cursor="default"
                  fontWeight="600"
                  border="none"
                  verticalAlign="middle"
                  color="white"
                  textAlign="center"
                  _hover={{ background: '#0056bd' }}
                  paddingInline="1"
                  py="1.5"
                >
                  {proposal.description ? (
                    <Popover trigger="hover" isLazy>
                      <PopoverTrigger>
                        <Flex
                          direction="column"
                          justifyContent="center"
                          h="100%"
                        >
                          <Text
                            fontSize={{ base: 'xs', md: '11px', xl: 'xs' }}
                            lineHeight="11px"
                            px="1"
                            pt="0"
                            pb="1"
                            width="100%"
                          >
                            {proposal.shortTitle}
                          </Text>
                        </Flex>
                      </PopoverTrigger>
                      <PopoverContent
                        p="10px"
                        // zIndex="10"
                        // position="relative"
                        // TODO: Content is not in front of Report Card text
                      >
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverBody
                          textAlign="left"
                          textTransform="none"
                          fontWeight="bold"
                          color="#4a5568"
                        >
                          <Text my="2" pr="2" fontSize="sm">
                            {proposal.title}
                          </Text>
                          {proposal.description && (
                            <MarkdownText
                              color="muted"
                              fontSize="sm"
                              fontWeight="400"
                            >
                              {proposal.description}
                            </MarkdownText>
                          )}
                          <Divider my="2" />
                          <Text color="muted" fontWeight="400" fontSize="sm">
                            For more information on the criteria used to grade
                            each country,{' '}
                            <Button
                              onClick={getTabId}
                              variant="link"
                              color="secondary"
                              fontSize="sm"
                              data-id="criteria"
                            >
                              click here
                            </Button>
                            .
                          </Text>
                        </PopoverBody>
                      </PopoverContent>
                    </Popover>
                  ) : (
                    <Text
                      fontSize={{ base: 'xs', md: '11px', xl: 'xs' }}
                      lineHeight="11px"
                      px="1"
                      pt="0"
                      pb="1"
                      width="100%"
                    >
                      {proposal.title}
                    </Text>
                  )}
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody id="table__body">
            {data.countries.map((country) => (
              <Row country={country} key={country.id} />
            ))}
          </Tbody>
        </Table>
      </Box>
      <style>
        {`

          #country__table tr:nth-child(even){
            background: #f8f8fb;
          }
          #country__table td{
            border: none;
          }
          // #country__table #table__head tr th:nth-child(3){
          //   display: none;
          // }
          // #country__table #table__body tr td:nth-child(3){
          //   display: none;
          // }
          // @media(max-width: 1700px){
          //   #country__table #table__head tr th:nth-last-of-type(-n+1){
          //     display: none;
          //   }
          //   #country__table #table__body tr td:nth-last-of-type(-n+1){
          //     display: none;
          //   }
          // }
          // @media(max-width: 1300px){
          //   #country__table #table__head tr th:nth-last-of-type(-n+2){
          //     display: none;
          //   }
          //   #country__table #table__body tr td:nth-last-of-type(-n+2){
          //     display: none;
          //   }
          // }
          @media(max-width: 1024px){
            #country__table #table__head tr th:nth-child(3){
              display: none;
            }
            #country__table #table__body tr td:nth-child(3){
              display: none;
            }
            // #country__table #table__head tr th:nth-last-of-type(-n+1){
            //   display: none;
            // }
            // #country__table #table__body tr td:nth-last-of-type(-n+1){
            //   display: none;
            // }
          }
          @media(max-width: 950px){
            #country__table #table__head tr th:nth-last-of-type(-n+2){
              display: none;
            }
            #country__table #table__body tr td:nth-last-of-type(-n+2){
              display: none;
            }
          }
          @media(max-width: 600px){
            #country__table #table__head tr th:nth-last-of-type(-n+7){
              display: none;
            }
            #country__table #table__body tr td:nth-last-of-type(-n+7){
              display: none;
            }
          }
          `}
      </style>
    </Stack>
  )
}
